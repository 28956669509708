<template>
  <LiefengContent>
    <template v-slot:title>国际化设置</template>
    <template v-slot:toolsbarLeft>
    </template>
    <template v-slot:toolsbarRight>
    <Form  :label-colon="true" :inline="true" class="search">
        <FormItem>
             <CascaderCity transfer @changeCasader="changeCasader" :resetNum="resetNum"></CascaderCity>
        </FormItem>
        <Button type="primary" style="margin-right:10px" @click="searchBtn" :disabled="isDisabled">查询</Button>
        <Button type="info" style="margin-right:10px" @click="resetBtn">重置</Button>
        <Button type="primary" style="margin-right:10px" @click="selectAll">全选</Button>
        <Button type="primary" style="margin-right:10px" @click="cancelAll">取消全选</Button>
    </Form>

    </template>
    <template v-slot:contentArea>
        <div class="table-div">
            <div class="table-container">
                 <div class="table-item">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData1"
                    :fixTable="true"
                    :hidePage="true"
                ></LiefengTable>
                    </div>
                <div class="table-item">
                    <LiefengTable
                            :talbeColumns="talbeColumns"
                            :tableData="tableData2"
                            :fixTable="true"
                            :hidePage="true"
                        ></LiefengTable>
                </div>
            </div>
        </div>
        <!-- <div class="select-div">
            <CheckboxGroup v-model="checkList"  @on-change="changeCheckbox">
                <Checkbox :border="true" v-for="(item,index) in tableData" :key="index" :label="item.orgId">{{item.orgName}}</Checkbox>
            </CheckboxGroup>
        </div> -->
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import CascaderCity from './children/CascaderCity'
import LiefengTable from "@/components/LiefengTable";
export default{
    components:{LiefengContent,CascaderCity,LiefengTable},
    data(){
        return{
            selectList:[],
            resetNum:0,
            tableData:[],
            // 多选框的内容
            checkList:[],
            isDisabled:false,
            talbeColumns:[
                {
                   title: "所属区",
                   align: "center",
                   minWidth: 100,
                   key:'zoneName'
                },
                {
                   title: "所属街道",
                   align: "center",
                   minWidth: 100,
                   key:'streetName'
                },
                {
                   title: "社区名称",
                   align: "center",
                   minWidth: 100,
                   key:'orgName'
                },
                {
                    title:'实名设置状态',
                    align:'center',
                    minWidth:80,
                    render: (h, params) => {
                        return h("Checkbox", {
                            props: {
                                value:params.row.value && params.row.value == '1' ? true : false
                            },
                            on: {
                                "on-change": (val) =>{
                                     if(val == true){
                                        this.checkList.push(params.row.orgId)
                                    }else if(val == false){
                                        this.lastChosse = params.row.orgId
                                        if(this.checkList && this.checkList.length){
                                            this.checkList.map((item,index) =>{
                                                if(item == params.row.orgId){
                                                    this.checkList.splice(index,1)
                                                }
                                            })
                                        }
                                    }
                                    
                                    this.checkList = Array.from(new Set(this.checkList))

                                    this.debounceCheck()
                                }
                            }
                        });
                    }
                },
               
            ],
            tableData1:[],
            tableData2:[],
            timer:null,
            lastChosse:''
        }
    },
    created(){
    },
    methods:{
        // 节流函数
        debounceCheck(){
                // 记录上一次的延时器
                this.timer = null
                // 清除上一次的延时器
                                                    console.log('走这里');
                clearTimeout(this.timer)
                this.timer = setTimeout(()=>{
                    this.changeCheckbox()
                },1000)
            
         },
        // 点击全选按钮
        selectAll(){
            let data = []
            this.tableData.map(item =>{
                data.push(item.orgId)
            })
            this.checkList = data
            let params = {
                orgIds:data.join(','),
                paramValue:'1'
            }
            this.updateRow(params)
            this.getList(this.selectList[this.selectList.length - 1])
        },
        // 取消全选
        cancelAll(){
            let data = []
            this.tableData.map(item =>{
                data.push(item.orgId)
            })
            let params = {
                orgIds:data.join(','),
                paramValue:'0'
            }
            this.updateRow(params)
            this.getList(this.selectList[this.selectList.length - 1])
        },
        // 点击了checkbox的change事件
        changeCheckbox(){
            if(this.lastChosse != ''){
                let data = {
                    orgIds:this.lastChosse,
                    paramValue:'0'
                }
                 this.updateRow(data,'none')
            }
            let params = {
                orgIds:this.checkList.join(','),
                paramValue:'1'
            }
            this.updateRow(params)
        },
        // 点击查询事件
        searchBtn(){
            if(this.selectList.length == 0){
                this.$Message.warning({
                    content:'请先选择地区',
                    background:true
                })
                return
            }
            this.getList(this.selectList[this.selectList.length - 1])
        },
        // 重置按钮事件
        resetBtn(){
            ++this.resetNum
            this.selectList = []
            this.tableData = []
        },
        // 选择的回调事件
        changeCasader(val){
            this.selectList = val
        },
        // 接口部分
        // 获取列表接口数据
        getList(orgCode){
            this.tableData = []
            this.tableData1 = []
            this.tableData2 = []
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            });
            this.isDisabled = true
            this.$get('/gx/pc//organization/para/selectLeftOrgParam',{
                orgCode,
                paramCode:'ORG_I18N'
            }).then(res=>{
                this.$Message.destroy();
                this.isDisabled = false
                if(res.code == 200){
                    this.checkList = []
                    this.tableData = res.dataList
                      res.dataList.map((item,index)=>{
                          if(item['param'].ORG_I18N == '1' == '1'){                       
                              this.checkList.push(item.orgId)
                              item['value'] = '1'
                          }
                          if(index == 0 || index % 2 == 0){
                              this.tableData1.push(item)
                          }else{
                                this.tableData2.push(item)
                            }
                        })
                }else{
                    this.$Message.error({
                        content:'获取数据失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 修改接口
        updateRow(data,status){
            this.$post('/gx/pc/organization/para/setLeftOrgParam',{
                orgIds:data.orgIds,
                paramCode:'ORG_I18N',
                paramValue:data.paramValue
            }).then(res=>{
                if(res.code == 200){
                    if(status) return
                    this.$Message.success({
                        content:"设置成功",
                        background:true
                    })
                    return
                }else{
                    this.$Message.error({
                        content:'设置失败',
                        background:true
                    })
                    this.getList(this.selectList[this.selectList.length - 1])
                    return
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.select-div{
    width: 100%;
    
}
.ivu-checkbox-group-item{
    min-width: 150px;
    margin: 10px;
}
.table-div{
    width: 100%;
    .table-container{
        width: 95%;
        margin: 0 auto;
        display: flex;
         .table-item{
            width: 45%;
            margin: 0 2.5%;
        }
    } 
}
</style>